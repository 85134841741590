import highlightjs from 'highlight.js/lib/common';
import Latex from 'react-latex-next';

function displayContent(content: Array<any>, codeBlock: boolean = false) {
  if (content === null || content === undefined)
    return (<p>My content appears to be missing?!</p>)

  let isCodeBlock = codeBlock;

  const contentElems: Array<any> = [];
  content.forEach((elem: any, index: number) => {
    switch (elem.nodeType) {
      case "paragraph":
        let classNameContent = "content-paragraph text-base text-left text-white";
        isCodeBlock = elem.content.every((contentElem) => contentElem.marks.find((mark) => mark.type === 'code'))
        if (isCodeBlock) {
          classNameContent = "";
        }
        contentElems.push(<div className={classNameContent} key={'para' + index}>{displayContent(elem.content, isCodeBlock)}</div>)
        break;

      case "embedded-asset-block":
        contentElems.push(
          <img className='img'
            key={'imag' + index}
            src={elem.data.target.fields.file.url}
            alt={elem.data.target.fields.file.fileName} />
        )
        break;

      case "text":
        if (elem?.marks[0]?.type === "code") {
          const code = highlightjs.highlightAuto(elem.value)
          if (isCodeBlock === true) {
            contentElems.push(<pre key={'textcode' + index} dangerouslySetInnerHTML={{ __html: code.value }}></pre>)
          } else {
            // this is a code element but within text
            contentElems.push(<a href='/#' key={'text' + index} dangerouslySetInnerHTML={{ __html: code.value }}></a>)
          }
        }
        else if (elem.value.includes("<latex>") && elem.value.includes("<_latex>")) {
          // remove tags
          let content = elem.value.replace('<latex>', "")
          content = content.replace("<_latex>", "")
          contentElems.push(<Latex>$${content}$$</Latex>)
        }
        else {
          contentElems.push(elem.value)
        }
        break;

      case "hr":
        contentElems.push(<div key={"div" + index} className='divider' />)
        break;

      case "unordered-list":
        contentElems.push(<ul key={"ul" + index} className="content-list">{displayContent(elem.content)}</ul>)
        break;

      case "list-item":
        contentElems.push(<li key={"li" + index}> {displayContent(elem.content)}</li >)
        break;

      case "hyperlink":
        if (elem.data.uri.includes("youtube.com") && elem.content[0]?.value.includes("_embed")) {
          const leafUri = elem.data.uri.split('=').pop()
          contentElems.push(
            <div className="iframe-container" key={"hyperlink" + index}>
              <iframe className="iframe-container inline content-yt-embed"
                src={"https://www.youtube.com/embed/" + leafUri} title="YT player" /></div>
          )
        }
        else {
          contentElems.push(<a key={"hyperlink" + index}
            className="link link-primary"
            href={elem.data.uri}>
            {elem.content[0]?.value}
          </a>)
        }
        break;

      case "heading-2":
        contentElems.push(
          <p className='text-xl text-left text-white content-heading-2 underline decoration-sky-500' key={'head2' + index}>{displayContent(elem.content)}</p>
        )
        break;
      default:
        console.error("Unknown nodeType: " + elem.nodeType);
        break;
    }
  })

  return contentElems
}

export default function CurrentContent({ content }) {
  return (
    <div className="content-bg">
      <div className="content">
        {displayContent(content?.fields?.content?.content)}
      </div>
    </div>
  )
}
