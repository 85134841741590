import { useEffect, useState } from 'react';
import './App.css';

import TitleBar from './TitleBar';
import CurrentContent from './CurrentContent';

class Posts {
  title: string | undefined;
  id: string | undefined;

  constructor(title: string, id: string) {
    this.title = title;
    this.id = id;
  }
}

export default function App({ contentClient }) {
  function stateHandler(contentId: string) {
    contentClient?.getEntry(contentId).then((entry: any) => {
      // console.log(entry)
      setContent(entry); setTitle(entry.fields.title);

      // Remove focus from the sub menu to force the menu to close
      const elem = document.activeElement;
      if (elem) {
        (elem as HTMLElement)?.blur();
      }
    }).catch(console.error)
  }

  const homePageId = "7iCry0dZP02nmLuguFZvQ2"
  const [currentTitle, setTitle] = useState<string>("Missing Content")
  const [currentContent, setContent] = useState<any>(null)
  const [blogDropdown, setBlogDropDown] = useState<Array<Posts> | null>(null)
  const [projectsDropdown, setProjectsDropDown] = useState<Array<Posts> | null>(null)

  useEffect(() => {
    contentClient?.getEntry(homePageId).then((entry: any) => {
      setContent(entry); setTitle(entry.fields.title)
    }).catch(console.error)

    contentClient?.getEntries({ content_type: "blogPost", order: '-sys.createdAt' }).then((entry: any) => {
      setBlogDropDown(entry.items.map((elem: any) => new Posts(elem.fields.title, elem.sys.id)))
    }).catch(console.error)

    contentClient?.getEntries({ content_type: "projects", order: '-sys.createdAt' }).then((entry: any) => {
      setProjectsDropDown(entry.items.map((elem: any) => new Posts(elem.fields.title, elem.sys.id)))
    }).catch(console.error)
  }, [contentClient]);

  return (
    <div>
      <TitleBar homePageId={homePageId} title={currentTitle} blogPosts={blogDropdown} projectPosts={projectsDropdown} stateHandler={stateHandler} />
      <CurrentContent content={currentContent} />
    </div>
  );
}

