function TitleBar({ homePageId, title, blogPosts, projectPosts, stateHandler }) {
  const logoColour = "#aaaaaa"
  const viewBox = "0 0 24 24"

  return (
    <div className="navbar">
      <div className="navbar-start">
        {/* Add mobile dropdown element*/}
        <div className="dropdown">
          <label tabIndex={0} className="btn btn-ghost lg:hidden">
            <svg xmlns="http://www.w2.org/2000/svg" className="h-5 w-5" fill="none" viewBox={viewBox} stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
          </label>
          <ul tabIndex={0} className="menu dropdown-content z-[1] bg-base-100 rounded-box w-56">
            <li><a href='/#' onClick={() => stateHandler(homePageId)}>Home</a></li>
            <li className="menu-title">
              <span>Blog</span>
            </li>
            <ul tabIndex={0} className='bg-base-100'>
              {
                blogPosts?.map((elem: any, index: number) => (
                  <li key={index} ><a href='/#' onClick={() => stateHandler(elem.id)}>{elem.title}</a></li>
                ))}
            </ul>
            <li className="menu-title">
              <span>Projects</span>
            </li>
            <ul tabIndex={0} className='bg-base-100'>
              {
                projectPosts?.map((elem: any, index: number) => (
                  <li key={index} ><a href='/#' onClick={() => stateHandler(elem.id)}>{elem.title}</a></li>
                ))}
            </ul>
          </ul>
        </div>
        {/* end of mobile addition */}
        <a className="btn btn-ghost hidden lg:flex" href='/#' onClick={() => stateHandler(homePageId)}>Home</a>
        <div className="dropdown dropdown-hover">
          <label tabIndex={0} className="btn btn-ghost hidden lg:flex">Blog</label>
          <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
            {
              blogPosts?.map((elem: any, index: number) => (
                <li key={index} ><a className="normal-case" href='/#' onClick={() => stateHandler(elem.id)}>{elem.title}</a></li>
              ))}
          </ul>
        </div>
        <div className="dropdown dropdown-hover">
          <label tabIndex={0} className="btn btn-ghost hidden lg:flex">Projects</label>
          <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
            {
              projectPosts?.map((elem: any, index: number) => (
                <li key={index} ><a className="normal-case" href='/#' onClick={() => { stateHandler(elem.id) }}>{elem.title}</a></li>
              ))}
          </ul>
        </div>
      </div>
      <div className="navbar-center">
        <a href='/#' className="label label-ghost text-xl">{title}</a>
      </div>
      <div className="navbar-end">
        <div className="join join-vertical lg:join-horizontal">
          {/* SVG for CV Buttons */}
          <a href={`${process.env.PUBLIC_URL}/thomas_lockie_cv.pdf`} download="Thomas_Lockie_cv.pdf">
            <button className="btn btn-ghost btn-square btn-xs join-item">
              <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill={logoColour} fillRule="evenodd" clipRule="evenodd">
                <path d="M3 24h19v-23h-1v22h-18v1zm17-24h-18v22h18v-22zm-1 1h-16v20h16v-20zm-2 16h-12v1h12v-1zm0-3h-12v1h12v-1zm0-3h-12v1h12v-1zm-7.348-3.863l.948.3c-.145.529-.387.922-.725 1.178-.338.257-.767.385-1.287.385-.643 0-1.171-.22-1.585-.659-.414-.439-.621-1.04-.621-1.802 0-.806.208-1.432.624-1.878.416-.446.963-.669 1.642-.669.592 0 1.073.175 1.443.525.221.207.386.505.496.892l-.968.231c-.057-.251-.177-.449-.358-.594-.182-.146-.403-.218-.663-.218-.359 0-.65.129-.874.386-.223.258-.335.675-.335 1.252 0 .613.11 1.049.331 1.308.22.26.506.39.858.39.26 0 .484-.082.671-.248.187-.165.322-.425.403-.779zm3.023 1.78l-1.731-4.842h1.06l1.226 3.584 1.186-3.584h1.037l-1.734 4.842h-1.044z" />
              </svg>
            </button>
          </a>
          {/* SVG E-Mail Logo*/}
          <button className="btn btn-ghost btn-square btn-xs join-item" onClick={
            () => {
              navigator.clipboard.writeText("tclockie@gmail.com");
              (document?.getElementById("email_modal") as HTMLDialogElement).showModal()
            }}>

            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox={viewBox}>
              <path stroke={logoColour} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 13h3.439a.991.991 0 0 1 .908.6 3.978 3.978 0 0 0 7.306 0 .99.99 0 0 1 .908-.6H20M4 13v6a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-6M4 13l2-9h12l2 9" />
            </svg>
          </button>

          <dialog id="email_modal" className="modal">
            <div className="modal-box">
              <h3 className="text-lg font-bold">tclockie@gmail.com</h3>
              <p className="py-4">has been copied to the clipboard</p>
              <form method="dialog">
                <button className="btn">Close</button>
              </form>
            </div>
          </dialog>

          <button className="btn btn-ghost btn-square btn-xs join-item">
            {/* SVG Twitter logo*/}
            <svg xmlns="http://www.w2.org/2000/svg" width="24" height="24" fill={logoColour} viewBox={viewBox} onClick={() => window.location.href = "https://twitter.com/ClassifiedGiant"}>
              <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
            </svg>
          </button>
          {/* SVG Instagram Logo */}
          <button className="btn btn-ghost btn-square btn-xs join-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={logoColour} viewBox={viewBox} onClick={() => window.location.href = "https://www.instagram.com/classifiedgiant"}>
              <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
            </svg>
          </button>
          <button className="btn btn-ghost btn-square btn-xs join-item">
            {/* SVG Linkedin logo*/}
            <svg xmlns="http://www.w2.org/2000/svg" width="24" height="24" fill={logoColour} viewBox={viewBox} onClick={() => window.location.href = "https://www.linkedin.com/in/tom-lockie-724093b"}>
              <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
            </svg>
          </button>
        </div>
      </div>
    </div >
  );
}

export default TitleBar;
